/**
 * @var gigya
 */
window.onGigyaServiceReady = function () {
  var screensetSetup = window.screensetSetup;
  var forcedScreenRenderingOnce = false;
  var forceScreenRendering = function forceScreenRendering(currentScreen) {
    if (screensetSetup.screenId && !forcedScreenRenderingOnce) {
      forcedScreenRenderingOnce = true;
      if (currentScreen !== screensetSetup.screenId) {
        console.debug("go to ".concat(screensetSetup.screenId));
        gigya.accounts.switchScreen({
          screenSet: screensetSetup.screenSet,
          containerID: screensetSetup.containerID,
          screen: screensetSetup.screenId
        });
      } else {
        console.debug("already into ".concat(screensetSetup.screenId));
      }
    }
    document.getElementById(showScreenSetConf.containerID).classList.remove('invisible');
  };
  var showScreenSetConf = {
    screenSet: screensetSetup.screenSet,
    containerID: screensetSetup.containerID,
    deviceType: screensetSetup.deviceType,
    authFlow: screensetSetup.authFlow,
    redirectURL: screensetSetup.redirectURL,
    onBeforeScreenLoad: function onBeforeScreenLoad(eventObj) {
      console.debug('onBeforeScreenLoad', eventObj);
      forceScreenRendering(eventObj.nextScreen);
    }
  };
  if (screensetSetup.screenId) {
    document.getElementById(showScreenSetConf.containerID).classList.add('invisible');
  }
  console.log(showScreenSetConf);
  gigya.accounts.showScreenSet(showScreenSetConf);
};